import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Header = ({ children }) => (
  <>
    <div style={{ backgroundColor: "yellow", fontSize: "medium" }}>
      <span
        style={{
          alignItems: `center`,
          justifyContent: `space-between`,
          display: `flex`,
        }}
      >
        <span>
          <OutboundLink href="https://personalwebsite.services/demo">
            🔙 Go back to Demo.&emsp;
          </OutboundLink>
        </span>
        <span>
          <OutboundLink href="https://www.stackrole.com">
            Foundation theme by Stackrole
          </OutboundLink>
        </span>
      </span>
    </div>
    <header className="site-header">{children}</header>
  </>
)

export default Header
