import { graphql, useStaticQuery } from 'gatsby';

const useSearchQuery = () => {
    const data = useStaticQuery(graphql`
        query blogListQuery222 {
          allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {template: {eq: "blog-post"}}}) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  slug
                  title
                  description
                  template
                }
                rawMarkdownBody
              }
            }
          }
        }
    `);
    return data;
};

export default useSearchQuery;
