import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Autosuggest from "react-autosuggest"
import useSearchQuery from './../graphql/use-search-query';
import * as JsSearch from 'js-search';

export default function SearchBox() {
  const [state, setState] = useState({
    value: "",
    suggestions: [],
  })

  const suggestions = [
  ]

  const all_data = useSearchQuery();
  const search = new JsSearch.Search(['node', 'id']);
  search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
  search.addDocuments(all_data.allMarkdownRemark.edges);
  search.addIndex(['node', 'frontmatter', 'title']);
  search.addIndex(['node', 'frontmatter', 'description']);
  search.addIndex(['node', 'excerpt']);
  search.addIndex(['node', 'rawMarkdownBody']);

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) {
        return [];
    }
    const g = search.search(inputValue);
    if (!g) {
        return [];
    }
    const truncated = g.slice(0, 8);
    let final_sugg = [];

    truncated.forEach(portion => {
        final_sugg.push({
            title: portion.node.frontmatter.title,
            url: portion.node.frontmatter.template === 'blog-post' ? '/blog'+portion.node.frontmatter.slug : portion.node.frontmatter.slug
        })
    });
    return final_sugg;
  }

  const getSuggestionValue = suggestion => suggestion.title

  const renderSuggestion = suggestion => (
    <Link to={suggestion.url} className="search-item">
      {suggestion.title}
    </Link>
  )

  const onChange = (event, { newValue }) => {
    setState(p => ({
      ...p,
      value: newValue,
    }))
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setState(p => ({
      ...p,
      suggestions: getSuggestions(value),
    }))
  }

  const onSuggestionsClearRequested = () => {
    setState(p => ({
      ...p,
      suggestions: [],
    }))
  }

  return (
    <div className="auto-suggest-container">
      <Autosuggest
        suggestions={state.suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={{
          placeholder: "Search",
          type: "text",
          value: state.value,
          onChange: onChange,
        }}
        renderSuggestionsContainer={renderSuggestionsContainer}
        theme={{
          container: "react-autosuggest__container",
          containerOpen: "react-autosuggest__container--open",
          input: "react-autosuggest__input",
          inputOpen: "react-autosuggest__input--open",
          inputFocused: "react-autosuggest__input--focused",
          suggestionsContainer: "react-autosuggest__suggestions-container",
          suggestionsContainerOpen:
            "react-autosuggest__suggestions-container--open",
          suggestionsList: "react-autosuggest__suggestions-list",
          suggestion: "react-autosuggest__suggestion",
          suggestionFirst: "react-autosuggest__suggestion--first",
          suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
          sectionContainer: "react-autosuggest__section-container",
          sectionContainerFirst: "react-autosuggest__section-container--first",
          sectionTitle: "react-autosuggest__section-title",
        }}
      />
    </div>
  )
}

function renderSuggestionsContainer({ containerProps, children, query }) {
  return (
    <div {...containerProps} className="suggestions-container">
      {children}
    </div>
  )
}


function onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
) {
    navigate(suggestion.url)
}
