import React from "react"
import {Link} from "gatsby"
import { RiHeart2Line } from "react-icons/ri";

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
        <Link to={'/newsletter'} >
          Newsletter
        </Link>
        <a href={'https://foundation-demo.personalwebsite.services/rss.xml'} style={{marginLeft: '5px'}} >
          RSS
        </a><p></p>
       Theme by <a href="https://www.stackrole.com/">Stackrole</a>
    </div>
  </footer>
)

export default Footer
