import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import { RiMenu3Line, RiCloseLine, RiCloseCircleLine } from "react-icons/ri"
import { FaSearch } from "react-icons/fa"
import SearchBox from "./searchbox"

const MenuItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/blog",
    title: "Blog",
  },
  {
    path: "/about",
    title: "About",
  },
  {
    path: "/contact",
    title: "Contact",
  },
]

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const SearchIcon = () => {

  const [searchOpen, setSearchOpen] = useState(false);
  const searchContainer = useRef(null);

  useEffect(() => {
    if(searchOpen && searchContainer) {
      searchContainer.current.querySelector("input").focus();
    }
  }, [searchOpen])

  return (
    <div className="search-icon-container">
      <div className="wide-screen">
        {searchOpen &&
          <div ref={searchContainer}>
            <SearchBox />
          </div>
        }
        <button onClick={() => {
          setSearchOpen(!searchOpen)
        }}>{searchOpen ? <RiCloseCircleLine size={24} /> : <FaSearch size={18}/>}</button>
      </div>
      <div className="small-screen">
        <SearchBox />
      </div>
    </div>
  )
}

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }

    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const listMenuItems = MenuItems.map((menuItem, index) => (
      <ListLink key={index} to={menuItem.path}>
        {menuItem.title}
      </ListLink>
    ))

    listMenuItems.push(
      <li className="search-icon-li" key={`nav-search`} style={{verticalAlign: 'middle'}}>
        <SearchIcon />
      </li>
    );

    return (
      <nav className="site-navigation">
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
        >
          <div className="icon-menu-line">
            <RiMenu3Line />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>
          {listMenuItems}
        </ul>
      </nav>
    )
  }
}

export default Navigation
